import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from '../../config';

// #region /////// COMPONENTS ///////
import LandingLoader from '../../components/LandingLoader';
import Modal from '../../components/Modal';
import Toast from '../../components/Toast';
import AppSectionRouter from '../AppSectionRouter';
import ProfileManager from '../ProfileManager';
// #endregion

import { isDesktop } from 'react-device-detect';
import { FatalErrorBoundary } from '../../HOCs/WithErrorBoundary';
import DownloadApp from '../../components/DownloadApp';
import { HandleError } from '../../components/HandleError';
import NotificationModal from '../../components/NotificationModal';
import { MOBILE_ENABLED_PATHS } from '../../constants/enabledMobile';
import { useAnalytics } from '../../firebase';
import classNames from '../../utils/classnameUtils';
import * as hooks from './hooks';
import * as selectors from './selectors';
import useClientConfigs from '../../customHooks/useClientConfigs';
import AppStore from '../../components/AppStore';

const HelpWidget = ({ userInfo }) => {
  React.useEffect(() => {
    if (window.FreshworksWidget && userInfo) {
      // Prefill customer details
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userInfo.name} ${userInfo.lastname}`,
        email: `${userInfo.email}`,
      });
    }
    return () => {};
  }, [userInfo]);

  return null;
};

const parseScriptString = (scriptString) => {
  const scriptDetails = {};
  if (scriptString) {
    // Extract src attribute
    const srcMatch = scriptString.match(/src=['"]([^'"]+)['"]/);
    if (srcMatch) {
      scriptDetails.src = srcMatch[1];
    }

    // Extract type attribute
    const typeMatch = scriptString.match(/type=['"]([^'"]+)['"]/);
    if (typeMatch) {
      scriptDetails.type = typeMatch[1];
    }

    // Extract innerHTML
    const innerHTMLMatch = scriptString.match(/<script[^>]*>([\s\S]*?)<\/script>/);
    if (innerHTMLMatch) {
      scriptDetails.innerHTML = innerHTMLMatch[1].trim();
    }
  }

  return scriptDetails;
};

function App({ history }) {
  const [widget, setWidget] = React.useState(null);
  const { ready } = useTranslation();
  // #region /////// SELECTORS ///////
  const accessToken = useSelector((state) =>
    selectors.selectAccessToken(state),
  );
  const error = useSelector((state) => selectors.selectError(state));
  const isFetching = useSelector((state) => selectors.selectIsFetching(state));
  const togglePorfilesModal = useSelector((state) =>
    selectors.selectToggleProfilesModal(state),
  );
  const notificationModal = useSelector((state) =>
    selectors.selectNotificationModal(state),
  );
  const appSettings = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const { mobileAppUrls } = appSettings;
  const accountInformation = useSelector((state) =>
    selectors.selectAccountInformation(state),
  );
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  // #endregion
  const showApp = ready && !isFetching && !!accessToken.access_token;
  const { hasPayService } = useClientConfigs();

  // #region /////// HOOK EFFECTS ///////
  useAnalytics();
  hooks.useDidMount(config, accessToken);
  hooks.useProfiles(accessToken);
  hooks.useCurrentProfileValidation(accessToken);
  hooks.useUserLogout();
  hooks.useNewRelicAttributes(accessToken);
  hooks.useRatingImages(accessToken);
  hooks.useGetAccountInformation(accessToken);
  hooks.useNotifications();
  hooks.useScript(parseScriptString(widget))
  /// /#endregion

  React.useEffect(() => {
    if(appSettings?.widgetSupport){
      setWidget(atob(appSettings?.widgetSupport))
    }
  }, [appSettings?.widgetSupport])
  

  // INFO: En caso de no tener la configuracion requerida de entorno
  // o unity devuelve error en el endpoint inicial para obtener token.
  if (!config || (error.code && error.message)) {
    return (
      <HandleError
        error={
          error?.code ? error : { error: 'FATAL: Startup configuration error.' }
        }
        navBar={false}
      />
    );
  }

  if (!showApp) return <LandingLoader />;
  if (
    !isDesktop &&
    !MOBILE_ENABLED_PATHS.find((r) => window.location.pathname.includes(r))
  ) {
    if (hasPayService) return <DownloadApp mobileAppUrls={mobileAppUrls} />;
    else return <AppStore mobileAppUrls={mobileAppUrls} />;
  }

  return (
    <React.Fragment>
      <AppSectionRouter history={history} />
      <Toast />
      <Modal
        show={togglePorfilesModal || notificationModal}
        classNameCustom={classNames(
          togglePorfilesModal ? 'tbxContentModalProfile' : '',
          notificationModal ? 'tbxNotificationModal' : '',
        )}
      >
        {(togglePorfilesModal && <ProfileManager history={history} />) ||
          (notificationModal && (
            <NotificationModal notificationModal={notificationModal} />
          ))}
      </Modal>
      {authenticated && (
        <HelpWidget
          userInfo={accountInformation?.info}
        />
      )}
    </React.Fragment>
  );
}

export default FatalErrorBoundary(App);

import { types } from './constants';

const INITIAL_STATE = {
  appSettings: {},
  error: {
    code: null,
    details: null,
    message: null,
  },
  isFetching: false,
  toggleProfilesModal: false,
  notificationModal: null,
  unityAuthData: {
    client: null,
    country: null,
    ipUserCountry: null,
    device: null,
    idp: null,
    language: null,
  },
  toast: [],
  networks: [],
  channels: [],
  firebaseToken: null,
  ratingImages: [],
  accountInfo: null,
  accountInfoError: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APP_DEVICE_AUTHN:
      return {
        ...state,
        isFetching: true,
      };
    case types.APP_DEVICE_AUTHN_FAILURE:
      const { error: spError } = action;
      return {
        ...state,
        error: {
          ...state.error,
          code: spError.errorCode,
          message: `"${spError.name}": ${spError.message}`,
        },
        isFetching: false,
      };
    case types.APP_STARTUP:
      return {
        ...state,
        isFetching: true,
      };
    case types.APP_STARTUP_SUCCESS:
      const { settings, networks, channels } = action;
      return {
        ...state,
        appSettings: {
          ...settings,
        },
        networks: networks.result,
        channels: channels.result,
        isFetching: false,
      };
    case types.APP_TOGGLE_PROFILES_MODAL:
      return {
        ...state,
        toggleProfilesModal: action.show,
      };
    case types.APP_NOTIFICATION_MODAL_CREATE:
      return {
        ...state,
        notificationModal: { ...action },
      };
    case types.APP_NOTIFICATION_MODAL_DELETE:
      return {
        ...state,
        notificationModal: null,
      };
    case types.APP_TOKEN_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error,
        },
        isFetching: true,
      };
    case types.APP_TOKEN_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error,
        },
        isFetching: false,
      };
    case types.APP_TOKEN_FETCH_SUCCESS:
      const { unityAuthData } = action;

      return {
        ...state,
        isFetching: false,
        unityAuthData: {
          client: unityAuthData.client,
          country: unityAuthData.country,
          ipUserCountry: unityAuthData.ipUserCountry,
          device: unityAuthData.device,
          idp: unityAuthData.idp,
          language: unityAuthData.language,
        },
      };
    case types.APP_TOAST_ADD:
      const toast = action;
      const toastResult = {
        key: Math.floor(Math.random() * Date.now()),
        ...toast,
      };
      return {
        ...state,
        toast: [toastResult, ...state.toast],
      };
    case types.APP_TOAST_DELETE:
      const { key } = action;
      return {
        ...state,
        toast: state.toast.filter((t) => t?.key !== key),
      };
    case types.FIREBASE_SET_TOKEN:
      const { token } = action;
      return {
        ...state,
        firebaseToken: token,
      };
    case types.RATING_IMAGES_FETCH_SUCCESS:
      const { ratingImages } = action;

      return {
        ...state,
        ratingImages: ratingImages?.result,
      };
    case types.LOAD_ACCOUNT_INFORMATION:
      return {
        ...state,
        accountInfoError: null,
      };
    case types.LOAD_ACCOUNT_INFORMATION_SUCCESS:
      return {
        ...state,
        accountInfo: action.payload,
      };
    case types.LOAD_ACCOUNT_INFORMATION_FAILURE:
      return {
        ...state,
        accountInfoError: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

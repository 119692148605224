import PropTypes from 'prop-types';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFavoriteContentByProfile,
  deleteFavoriteContentByProfile,
} from '../../ProfileManager/actions';

// #region /////// COMPONENTS ///////
import Footer from '../../../components/Footer';
import HStack from '../../../components/HStack';
// #endregion

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HandleError } from '../../../components/HandleError';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Seo from '../../../components/Seo';
import { CONTENT_TYPES } from '../../../constants/contentTypes';
import useRouter from '../../../customHooks/useRouter';
import { validateContentByEnableContentPage } from '../../../utils/ContentUtils';
import {
  convertPath,
  getPathNameByContentType,
} from '../../../utils/pathRoutesUtils';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import useBrandInfo from '../../../customHooks/useBrandInfo';
import { selectPathCountry } from '../../AppSectionRouter/selectors';
import * as actions from './actions';
import * as hooks from './hooks';
import * as selectors from './selectors';
import './styles.scss';

function HomeSection({ accessToken, section }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [showFooter, setShowFooter] = React.useState(false);
  const { t } = useTranslation();
  const BRAND_INFO = useBrandInfo();

  // #region /// SELECTORS ///
  const widgetCollection = useSelector((state) =>
    selectors.selectWidgetCollection(state),
  );
  const appSettings = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const networks = useSelector((state) => selectors.selectNetworks(state));
  const channels = useSelector((state) => selectors.selectChannels(state));
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const favorites = useSelector((state) =>
    selectors.selectFavoritesContents(state),
  );
  const widgetCollectionPaginate = useSelector((state) =>
    selectors.selectWidgetCollectionPaginate(state),
  );
  const selectIsPlayerEpgSection = useSelector((state) =>
    selectors.selectIsPlayerEpgSection(state),
  );
  const selectSectionError = useSelector((state) =>
    selectors.selectSectionError(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const pathCountry = useSelector((state) => selectPathCountry(state));
  const { enableContentPage } = appSettings;
  // #endregion

  const handleOnSelectContent = async (content) => {
    if (validateContentByEnableContentPage(enableContentPage, content)) {
      if (!isDesktop) {
        alert('Visualiza este contenido descargando la app');
      } else {
        if (checkifHaveContentAccess(authenticated, content)) {
          // GO TO PLAYER
          router.push(convertPath(PATHS.player, content.id));
          return false;
        } else {
          // REDIRECT TO LOGIN
          const { origin } = window.location;
          const returnURL = origin + convertPath(PATHS.player, content.id);
          router.push(PATHS.login, { returnURL });
          return false;
        }
      }
    } else {
      const subPath = getPathNameByContentType(content.contentType, PATHS);
      router.push(convertPath(subPath, content.id));
      setShowFooter(false);
    }
  };

  const onClickPlayerButton = (content, isBannerHero = false) => {
    if (!isDesktop) {
      alert('Visualiza este contenido descargando la app');
      return false;
    }

    if (checkifHaveContentAccess(authenticated, content)) {
      if (content?.contentType === CONTENT_TYPES.SERIE) {
        dispatch(
          actions.getCurrentEpisodeBySerie(content.id, router, PATHS.player),
        );
      } else {
        router.push(convertPath(PATHS.player, content.id));
      }
      return false;
    }

    const { origin } = window.location;
    const returnPath =
      content?.contentType !== CONTENT_TYPES.SERIE
        ? PATHS.player
        : PATHS.content;
    const returnURL = origin + convertPath(returnPath, content.id);
    router.push(PATHS.login, { returnURL });
  };

  const onClickMyListButton = (contentId) => {
    favorites.some((idR) => idR === contentId)
      ? dispatch(deleteFavoriteContentByProfile(contentId))
      : dispatch(addFavoriteContentByProfile(contentId));
  };

  // #region ///// HOOK EFFECTS /////
  hooks.useDidMount(accessToken, section);
  // hooks.useSectionError(selectSectionError);
  // #endregion

  const redirectHandler = (content, redirectType, target = '_self') => {
    return redirectType === 'externalUrl'
      ? window.open(content, target)
      : router.push(`${pathCountry}${content}`);
  };

  if (selectSectionError?.code === 'SE-001') {
    return (
      <HandleError
        error={{
          error: t(
            'There is no home section available. Check with the system administrator.',
          ),
        }}
        navBar={false}
      />
    );
  }

  return (
    <main
      // eslint-disable-next-line react/no-unknown-property
      onLoad={() => setShowFooter(true)}
      className="main-content homeSection"
    >
      {section && (
        <Seo
          title={`${BRAND_INFO.CLIENT_NAME} - ${section.name}`}
          contentTitle={`${BRAND_INFO.CLIENT_NAME}`}
          description={BRAND_INFO.CLIENT_DESCRIPTION}
          image={'/images/logo.png'}
        />
      )}

      <div className="container-wide hstack-container">
        <InfiniteScroll
          dataLength={widgetCollectionPaginate?.length}
          next={() => dispatch(actions.loadMoreWidgets())}
          hasMore={widgetCollectionPaginate?.length < widgetCollection?.length}
          loader={
            <div className="homeSection__results-loader">
              <LoadingSpinner className="homeSection__results-loader-spinner" />
            </div>
          }
        >
          <HStack
            accessToken={accessToken}
            handleOnSelectContent={handleOnSelectContent}
            onClickPlayerButton={onClickPlayerButton}
            onClickMyListButton={onClickMyListButton}
            favorites={favorites}
            authenticated={authenticated}
            sectionID={section.id}
            widgetCollection={widgetCollectionPaginate}
            appSettings={appSettings}
            networks={networks}
            channels={channels}
            redirectHandler={redirectHandler}
          />
        </InfiniteScroll>
      </div>
      {!selectIsPlayerEpgSection && showFooter && <Footer />}
    </main>
  );
}

HomeSection.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    isHome: PropTypes.bool,
    isPublic: PropTypes.bool,
    name: PropTypes.string.isRequired,
    redirect: PropTypes.object,
    seo: PropTypes.object,
  }),
};

export default HomeSection;
